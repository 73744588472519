/* eslint-disable camelcase */
/* eslint-disable filenames/match-exported */

import Typography from '@mui/material/Typography';
import { boolean } from 'boolean';
import { isString, noop } from 'lodash';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import React, { createContext, useState } from 'react';

import { GetFeatureFlagsPayloadQuery, useGetFeatureFlagsPayloadQuery, UtilityFeatureFlagFragment } from '~/__generated__/graphql';
import Redirect from '~/components/Redirect';
import FEATURE_FLAGS from '~/constants/FEATURE_FLAGS';
import { isStorybookEnv } from '~/helpers';

const FEATURE_FLAG_REFRESH_TIMER_INTERVAL = 1000 * 60 * 5; // 5 minutes

export interface IFeatureFlags {
  [key: UtilityFeatureFlagFragment['key']]: UtilityFeatureFlagFragment['value'];
  [FEATURE_FLAGS.MAINTENANCE_SEVERITY]: string | false;
  [FEATURE_FLAGS.MAINTENANCE_START_DATE]: string | false;
  [FEATURE_FLAGS.MAINTENANCE_END_DATE]: string | false;
  [FEATURE_FLAGS.JMT_TRANSITION_MOCK_DATE]: string | false;
  [FEATURE_FLAGS.APP_REFRESHER_INTERVAL]: number | null;
  [FEATURE_FLAGS.WEB_DEBUG]: boolean | false;
  [FEATURE_FLAGS.WEB_SMARTBANNER]: boolean | false;
  [FEATURE_FLAGS.CMS_ARTICLE]: boolean | false;
  [FEATURE_FLAGS.CTA_CARD_FULL_PROFILE_SURVEY]: boolean | false;
}

interface IFeatureFlagContext {
  featureFlags?: IFeatureFlags;
  setFeatureFlagsDebug: (featureFlags: IFeatureFlags | undefined) => void;
}

// This context contains the featureFlags
export const FeatureFlagContext = createContext<IFeatureFlagContext>({
  setFeatureFlagsDebug: noop,
});

// Frontend overrides (code smell)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const processFeatureFlagQueryResponse = (data: GetFeatureFlagsPayloadQuery['utilitiesFeatureFlags']): IFeatureFlags => {
  const featureFlags: IFeatureFlags = {
    [FEATURE_FLAGS.APP_REFRESHER_INTERVAL]: null,
    [FEATURE_FLAGS.MAINTENANCE_SEVERITY]: false,
    [FEATURE_FLAGS.MAINTENANCE_START_DATE]: false,
    [FEATURE_FLAGS.MAINTENANCE_END_DATE]: false,
    [FEATURE_FLAGS.WEB_DEBUG]: false,
    [FEATURE_FLAGS.JMT_TRANSITION_MOCK_DATE]: false,
    [FEATURE_FLAGS.WEB_SMARTBANNER]: false,
    [FEATURE_FLAGS.CMS_ARTICLE]: false,
    [FEATURE_FLAGS.CTA_CARD_FULL_PROFILE_SURVEY]: false,
  };
  if (data.length) {
    data.forEach(flag => {
      switch (flag.key) {
        case FEATURE_FLAGS.MAINTENANCE_SEVERITY:
        case FEATURE_FLAGS.MAINTENANCE_START_DATE:
        case FEATURE_FLAGS.MAINTENANCE_END_DATE:
        case FEATURE_FLAGS.JMT_TRANSITION_MOCK_DATE:
          if (isString(flag.value) && flag.value !== '') featureFlags[flag.key] = flag.value;
          break;
        case FEATURE_FLAGS.WEB_DEBUG:
        case FEATURE_FLAGS.WEB_SMARTBANNER:
        case FEATURE_FLAGS.CMS_ARTICLE:
        case FEATURE_FLAGS.CTA_CARD_FULL_PROFILE_SURVEY:
          featureFlags[flag.key] = boolean(flag.value);
          break;
        default:
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          featureFlags[flag.key] = flag.value;
      }
    });
  }

  return featureFlags;
};
const FeatureFlagProvider = FeatureFlagContext.Provider;

const FeatureFlagProviderComponent: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState<IFeatureFlags | undefined>();
  const searchParams = useSearchParams();
  const { query } = useRouter();
  const keys = Object.keys(query);

  // Memoize the override feature flags using the provided keys and search parameters.
  const overrideFeatureFlag = React.useMemo(() => {
    return keys.reduce((acc: Record<string, boolean>, key) => {
      const value = searchParams.get(key); // Get the value from search parameters.
      if (value) {
        // not all flags are true/false, but this only supports true/false right now
        acc[key] = boolean(value); // Add the key-value pair to the accumulator if a value exists.
      }
      return acc; // Return the accumulator.
    }, {}); // Initialize with an empty object.
  }, [keys, searchParams]); // Dependencies: keys and searchParams.

  /*
  Example Input:
    keys = ['feature_a', 'feature_b', 'feature_c'];
    searchParams = new URLSearchParams('?feature_a=true&feature_c=enabled');

  Example Output:
    overrideFeatureFlag = { feature_a: 'true', feature_c: 'enabled' };
  */
  // Check if there are any overrides.
  let mergedFeatureFlags = featureFlags;
  if (Object.keys(overrideFeatureFlag).length > 0 && featureFlags) {
    // Merge existing flags with overrides.
    mergedFeatureFlags = { ...featureFlags, ...overrideFeatureFlag };
  }

  const gqlQueryResult = useGetFeatureFlagsPayloadQuery({
    onCompleted: data => {
      const featureFlagsPayload = processFeatureFlagQueryResponse(data.utilitiesFeatureFlags);
      setFeatureFlags(featureFlagsPayload);
    },
    onError: () => {
      // If we get a network error on first poll, load flags in their default state so we aren't in loading limbo
      if (mergedFeatureFlags === undefined) {
        setFeatureFlags(processFeatureFlagQueryResponse([]));
      }
    },
    pollInterval: FEATURE_FLAG_REFRESH_TIMER_INTERVAL,
  });

  return (
    <FeatureFlagProvider value={{ featureFlags: mergedFeatureFlags, setFeatureFlagsDebug: setFeatureFlags }}>
      {mergedFeatureFlags?.[FEATURE_FLAGS.WEB_DEBUG] && !gqlQueryResult.error && (
        <Typography sx={{ display: 'none' }}>Feature Flags loaded successfully. Check network call for payload details</Typography>
      )}
      {children}
    </FeatureFlagProvider>
  );
};

export default FeatureFlagProviderComponent;

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IGuardFeatureFlag_deprecatedProps {
  children: React.ReactNode;
  flag: string;
  isReplace?: boolean;
  redirect: string;
  shouldRedirect?: boolean;
}

/** @deprecated use GuardFeatureFlag instead  */
export const GuardFeatureFlag_deprecated = ({ children, flag, isReplace, redirect, shouldRedirect = true }: IGuardFeatureFlag_deprecatedProps) => {
  if (isStorybookEnv) return <>{children}</>;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { featureFlags } = React.useContext(FeatureFlagContext);

  // Return `null` until we know if this feature is on or off
  if (!featureFlags) return null;

  const isEnabled = boolean(featureFlags[flag]);
  if (isEnabled) return <>{children}</>;
  if (shouldRedirect) return <Redirect isReplace={isReplace} to={redirect} />;
  return null;
};

interface IGuardFeatureFlagProps {
  children: React.ReactNode;
  flag: string;
  fallback: JSX.Element | null;
}

export const GuardFeatureFlag = ({ children, flag, fallback }: IGuardFeatureFlagProps) => {
  if (isStorybookEnv) return <>{children}</>;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { featureFlags } = React.useContext(FeatureFlagContext);

  // Return `null` until we know if this feature is on or off
  if (!featureFlags) return null;

  const isEnabled = boolean(featureFlags[flag]);
  if (isEnabled) return <>{children}</>;
  return fallback || null;
};
